import Vue from 'vue'
import VueRouter from 'vue-router'

import home from './routes/home'
import room from './routes/room'
import dining from './routes/dining'
import wellness from './routes/wellness'
import meetingAndEvent from './routes/meetingAndEvent'
import offers from './routes/offers'
import gallery from './routes/gallery'

Vue.use(VueRouter)

const routerPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return routerPush.call(this, location).catch(error => error)
}

const router = new VueRouter({
  mode: 'history',
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    ...home,
    ...room,
    ...dining,
    ...wellness,
    ...meetingAndEvent,
    ...offers,
    ...gallery,
  ],
})

export default router
