import Vue from 'vue'
import { ToastPlugin, ModalPlugin, BootstrapVueIcons } from 'bootstrap-vue'
import VueImg from 'v-img'
import VueFbCustomerChat from 'vue-fb-customer-chat'
import VueGtag from 'vue-gtag'
import App from './App.vue'
import router from './router'
import store from './store'

import 'bootstrap-vue/dist/bootstrap-vue-icons.min.css'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'

// Global Components
import './global-components'

import i18n from './libs/i18n'

// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)
Vue.use(BootstrapVueIcons)

// VueFbCustomerChat
Vue.use(VueFbCustomerChat, {
  page_id: 381145299108477, //  change 'null' to your Facebook Page ID,
  theme_color: '#333333', // theme color in HEX
  locale: 'en_US', // default 'en_US'
})

Vue.use(VueImg, {
  altAsTitle: true,
  sourceButton: false,
  thumbnails: true,
})

// google analytics
Vue.use(VueGtag, {
  config: {
    id: 'G-XTSYDT1VBF',
    params: {
      send_page_view: false,
    },
  },
}, router)

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

const app = new Vue({
  router,
  store,
  i18n,
  render: h => h(App),
}).$mount('#app')

export default app
